export const initButtons = (body: HTMLBodyElement) => {
  const colorsPrimaryButtons = [
    'green',
    'blue-1',
    'blue-2',
    'purple',
    'red',
    'orange',
    'yellow'
  ];
  const colorsSecondaryButtons = ['orange', 'yellow', 'green', 'blue-2'];
  let indexPrimary = 0;
  let indexSecondary = 0;
  let canChangeClass = true;
  let interval: NodeJS.Timeout | null = null;

  body.classList.add('btn-hover');
  body.addEventListener(
    'mouseenter',
    (e: MouseEvent) => {
      if (
        (e.target as HTMLElement).classList.contains('btn--primary') ||
        (e.target as HTMLElement).classList.contains('mktoButton')
      ) {
        body.setAttribute('data-hover', 'true');
        if (!canChangeClass) return;
        canChangeClass = false;
        if (indexPrimary !== 0)
          body.classList.remove(
            `btn-primary-hover--${colorsPrimaryButtons[indexPrimary - 1]}`
          );
        else if (
          indexPrimary === 0 &&
          body.classList.contains(
            `btn-primary-hover--${
              colorsPrimaryButtons[colorsPrimaryButtons.length - 1]
            }`
          )
        )
          body.classList.remove(
            `btn-primary-hover--${
              colorsPrimaryButtons[colorsPrimaryButtons.length - 1]
            }`
          );

        body.classList.add(
          `btn-primary-hover--${colorsPrimaryButtons[indexPrimary]}`
        );
        indexPrimary =
          indexPrimary === colorsPrimaryButtons.length - 1
            ? 0
            : indexPrimary + 1;
      } else if (
        (e.target as HTMLElement).classList.contains('btn--secondary')
      ) {
        body.setAttribute('data-hover', 'true');

        if (!canChangeClass) return;
        canChangeClass = false;
        if (indexSecondary !== 0)
          body.classList.remove(
            `btn-secondary-hover--${colorsSecondaryButtons[indexSecondary - 1]}`
          );
        else if (
          indexSecondary === 0 &&
          body.classList.contains(
            `btn-secondary-hover--${
              colorsSecondaryButtons[colorsSecondaryButtons.length - 1]
            }`
          )
        )
          body.classList.remove(
            `btn-secondary-hover--${
              colorsSecondaryButtons[colorsSecondaryButtons.length - 1]
            }`
          );

        body.classList.add(
          `btn-secondary-hover--${colorsSecondaryButtons[indexSecondary]}`
        );
        indexSecondary =
          indexSecondary === colorsSecondaryButtons.length - 1
            ? 0
            : indexSecondary + 1;
      }
    },
    true
  );

  document.body.addEventListener(
    'mouseleave',
    (e: MouseEvent) => {
      if (
        !(e.target as HTMLElement).classList.contains('btn') &&
        !(e.target as HTMLElement).classList.contains('mktoButton')
      )
        return;
      body.setAttribute('data-hover', 'false');
      clearInterval(interval as NodeJS.Timeout);
      interval = setInterval(() => {
        if (body.getAttribute('data-hover') === 'false') {
          canChangeClass = true;
        }
      }, 250);
    },
    true
  );
};

export const initPageDecoration = (document: Document) => {
  const main = document.querySelector('main.page-top-angle') as HTMLElement;

  if (main) {
    const span = document.createElement('span');
    span.classList.add('page-decoration');
    main.appendChild(span);
  }
};
