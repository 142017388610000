// /**
// * Key code list object
// */
export const breakpoints = {
  mobile: 360,
  mobileLarge: 480,
  tablet: 768,
  tabletLandscape: 1024,
  desktop: 1280,
  wide: 1440
};
